// src/pages/SoftwareSolutions.tsx

import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import softwareImage from '../../assets/hero-9bricks.webp';
import './SoftwareSolutions.scss';
import { useTranslation } from 'react-i18next';

const SoftwareSolutions: React.FC = () => {
  const { t } = useTranslation('softwareSolutions');

  const benefits: string[] = t('Benefits', { returnObjects: true }) as string[];

  return (
      <Container className="software-solutions-container">
        <Grid container spacing={4}>
          <Grid item xs={12} md={6} className="text-column">
            <Typography variant="h3" className="title">
              {t('Title')}
            </Typography>
            <Typography variant="h6" className="subtitle">
              {t('Subtitle')}
            </Typography>
            <Typography variant="body1" className="description">
              {t('Description1')}
            </Typography>
            <Typography variant="body1" className="description">
              {t('Description2')}
            </Typography>
            <Typography variant="h6" className="subtitle">
              {t('WhyChooseUs')}
            </Typography>
            <Typography variant="body1" className="description">
              <ul>
                {benefits.map((benefit, index) => (
                    <li key={index}>{benefit}</li>
                ))}
              </ul>
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} className="image-column">
            <Box className="image-container">
              <img
                  src={softwareImage}
                  alt={t('ImageAlt')}
                  className="image"
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
  );
};

export default SoftwareSolutions;
