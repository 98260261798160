import React, { useState, useEffect } from "react";
import {
  AppBar,
  Toolbar,
  Button,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close"; // Import CloseIcon
import { Link, useLocation } from "react-router-dom"; // Import useLocation
import logo from "../assets/logo-9BR.png";
import "./Navbar.scss";
import { useTranslation } from "react-i18next";
import USFlag from "../components/flags/USFlag";
import VNFlag from "../components/flags/VNFlag";

const Navbar: React.FC = () => {
  const location = useLocation(); // Use useLocation to detect location changes
  const [scrolled, setScrolled] = useState(false);
  const { t, i18n } = useTranslation("navbar");

  // State cho Drawer (menu mobile)
  const [drawerOpen, setDrawerOpen] = useState(false);

  // State và handler cho Menu ngôn ngữ trên Desktop
  const [anchorElLangDesktop, setAnchorElLangDesktop] =
    useState<null | HTMLElement>(null);
  const openLangMenuDesktop = Boolean(anchorElLangDesktop);

  const handleLangMenuClickDesktop = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLangDesktop(event.currentTarget);
  };

  const handleLangMenuCloseDesktop = () => {
    setAnchorElLangDesktop(null);
  };

  const changeLanguageDesktop = (lng: string) => {
    i18n.changeLanguage(lng);
    handleLangMenuCloseDesktop();
  };

  // State và handler cho Menu ngôn ngữ trong Drawer (Mobile)
  const [anchorElLangMobile, setAnchorElLangMobile] =
    useState<null | HTMLElement>(null);
  const openLangMenuMobile = Boolean(anchorElLangMobile);

  const handleLangMenuClickMobile = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElLangMobile(event.currentTarget);
  };

  const handleLangMenuCloseMobile = () => {
    setAnchorElLangMobile(null);
  };

  const changeLanguageMobile = (lng: string) => {
    i18n.changeLanguage(lng);
    handleLangMenuCloseMobile();
    toggleDrawer(false)(); // Đóng Drawer sau khi chọn ngôn ngữ
  };

  const getCurrentFlag = () => {
    switch (i18n.language) {
      case "en":
        return <USFlag />;
      case "vi":
        return <VNFlag />;
      default:
        return <VNFlag />;
    }
  };

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  const isActive = (path: string) => location.pathname === path;

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 0);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  // UseEffect to scroll to top when route changes
  useEffect(() => {
    window.scrollTo(0, 0); // Cuộn trang lên đầu khi route thay đổi
  }, [location]);

  // Optional: Reload page on route change
  // You can also trigger a full reload of the page if needed:
  // useEffect(() => {
  //   window.location.reload();
  // }, [location]);

  return (
    <AppBar
      position="fixed"
      color="transparent"
      elevation={0}
      className={`navbar ${scrolled ? "scrolled" : ""}`}
    >
      <Toolbar className="toolbar">
        <div className="logo-placeholder">
          <Link to="/" style={{ textDecoration: "none", color: "#333" }}>
            <img src={logo} alt="9Bricks Logo" style={{ height: "40px" }} />
          </Link>
        </div>

        {/* Menu Desktop */}
        <nav className="nav-links">
          <Button
            color="inherit"
            component={Link}
            to="/software-solutions"
            className={isActive("/software-solutions") ? "active" : ""}
          >
            {t("Expertise")}
          </Button>
          {/* <Button
            color="inherit"
            component={Link}
            to="/products"
            className={isActive("/products") ? "active" : ""}
          >
            {t("Products")}
          </Button> */}
          
          <Button
            color="inherit"
            component={Link}
            to="/insights"
            className={isActive("/insights") ? "active" : ""}
          >
            {t("Insights")}
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/our-journey"
            className={isActive("/our-journey") ? "active" : ""}
          >
            {t("OurJourney")}
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/jobs"
            className={isActive("/jobs") ? "active" : ""}
          >
            {t("Jobs")}
          </Button>
          <Button
            color="inherit"
            component={Link}
            to="/contact"
            className={isActive("/contact") ? "active" : ""}
          >
            {t("Contact")}
          </Button>
          {/* Language Switcher Dropdown */}
          <Button
            color="inherit"
            onClick={handleLangMenuClickDesktop}
            startIcon={getCurrentFlag()}
            endIcon={<span style={{ fontSize: "12px" }}>▼</span>}
          >
            {i18n.language.toUpperCase()}
          </Button>
          
          <Menu
            anchorEl={anchorElLangDesktop}
            open={openLangMenuDesktop}
            onClose={handleLangMenuCloseDesktop}
          >
            <MenuItem onClick={() => changeLanguageDesktop("en")}>
              <USFlag /> &nbsp; {t("EN")}
            </MenuItem>
            <MenuItem onClick={() => changeLanguageDesktop("vi")}>
              <VNFlag /> &nbsp; {t("VI")}
            </MenuItem>
          </Menu>
        </nav>

        {/* Icon Menu cho Mobile */}
        <IconButton
          edge="end"
          color="inherit"
          aria-label="menu"
          className="burger-menu"
          onClick={toggleDrawer(!drawerOpen)}
        >
          {drawerOpen ? <CloseIcon /> : <MenuIcon />}
        </IconButton>
      </Toolbar>
      <div className="footer-divider"></div>

      {/* Drawer cho Mobile */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        className="drawer"
        classes={{ paper: "drawer-paper" }}
      >
        <List>
          <ListItem
            component={Link}
            to="/software-solutions"
            onClick={toggleDrawer(false)}
            className={isActive("/software-solutions") ? "active" : ""}
          >
            <ListItemText primary={t("Expertise")} />
          </ListItem>
          {/* <ListItem
            component={Link}
            to="/products"
            onClick={toggleDrawer(false)}
            className={isActive("/products") ? "active" : ""}
          >
            <ListItemText primary={t("Products")} />
          </ListItem> */}
          <ListItem
            component={Link}
            to="/insights"
            onClick={toggleDrawer(false)}
            className={isActive("/insights") ? "active" : ""}
          >
            <ListItemText primary={t("Insights")} />
          </ListItem>
          <ListItem
            component={Link}
            to="/our-journey"
            onClick={toggleDrawer(false)}
            className={isActive("/our-journey") ? "active" : ""}
          >
            <ListItemText primary={t("OurJourney")} />
          </ListItem>
          
          <ListItem
            component={Link}
            to="/jobs"
            onClick={toggleDrawer(false)}
            className={isActive("/jobs") ? "active" : ""}
          >
            <ListItemText primary={t("Jobs")} />
          </ListItem>
          <ListItem
            component={Link}
            to="/contact"
            onClick={toggleDrawer(false)}
            className={isActive("/contact") ? "active" : ""}
          >
            <ListItemText primary={t("Contact")} />
          </ListItem>
          <Divider />
          {/* Language Switcher in Drawer */}
          <ListItem onClick={handleLangMenuClickMobile}>
            <ListItemText
              primary={
                <span style={{ display: "flex", alignItems: "center" }}>
                  {getCurrentFlag()} &nbsp; {t("Language")} &nbsp; ▼
                </span>
              }
            />
          </ListItem>
          <Menu
            anchorEl={anchorElLangMobile}
            open={openLangMenuMobile}
            onClose={handleLangMenuCloseMobile}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            transformOrigin={{ vertical: "top", horizontal: "right" }}
          >
            <MenuItem onClick={() => changeLanguageMobile("en")}>
              <USFlag /> &nbsp; {t("EN")}
            </MenuItem>
            <MenuItem onClick={() => changeLanguageMobile("vi")}>
              <VNFlag /> &nbsp; {t("VI")}
            </MenuItem>
          </Menu>
        </List>
      </Drawer>
    </AppBar>
  );
};

export default Navbar;
