import React from "react";
import { Typography } from "@mui/material";
import "./InfoCircles.scss";

const InfoCircles: React.FC = () => (
    <div className="info-circles">
        <div className="circle">
            <Typography variant="h3" className="circle-number">2</Typography>
            <Typography variant="body2" className="circle-text">Years</Typography>
        </div>
        <div className="circle">
            <Typography variant="h3" className="circle-number">90k+</Typography>
            <Typography variant="body2" className="circle-text">Hours</Typography>
        </div>
        <div className="circle">
            <Typography variant="h3" className="circle-number">25+</Typography>
            <Typography variant="body2" className="circle-text">Customers</Typography>
        </div>
    </div>
);

export default InfoCircles;
