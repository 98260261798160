import React, { useState } from "react";
import { Container, Typography, Box, TextField, Button } from "@mui/material";
import "./Contact.scss";
import { useTranslation } from "react-i18next";

const Contact: React.FC = () => {
  const { t } = useTranslation("contact");
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    const form = new FormData();
    form.append("entry.290063712", formData.email);
    form.append("entry.867240424", formData.name);
    form.append("entry.1109646702", formData.subject);
    form.append("entry.1850757447", formData.message);

    try {
      await fetch(
        "https://docs.google.com/forms/u/0/d/e/1FAIpQLSfeNhtGZ5QKhg9_mHfIx7uUONBwdTuD2GtW7QCrhf6t9uhJjw/formResponse",
        {
          method: "POST",
          body: form,
          mode: "no-cors",
        }
      );
      alert(t("FormSubmitted"));

      // Reset the form data after successful submission
      setFormData({
        name: "",
        email: "",
        subject: "",
        message: "",
      });

      // Scroll to the top of the page after submit
      window.scrollTo(0, 0);

    } catch (error) {
      alert(t("FormSubmitError"));
    }
  };

  return (
    <Container className="contact-container">
      <Typography variant="h4" className="title">
        {t("Title")}
      </Typography>
      <Typography variant="body1" className="intro">
        {t("Intro")}
      </Typography>

      {/* Contact Information */}
      <Box className="contact-info">
        <Typography variant="h6">{t("ContactInfoTitle")}</Typography>
        {/* <Typography variant="body2">{t("ContactInfo.Address")}</Typography> */}
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: t('ContactInfo.Phone') }} />
        <Typography variant="body2" dangerouslySetInnerHTML={{ __html: t('ContactInfo.Email') }} />
      </Box>

      {/* Contact Form */}
      <Box component="form" onSubmit={handleSubmit} className="contact-form">
        <Typography variant="h6">{t("FormTitle")}</Typography>
        <TextField
          label={t("FormFields.Name")}
          variant="outlined"
          fullWidth
          margin="normal"
          required
          name="name"
          value={formData.name}
          onChange={handleChange}
        />
        <TextField
          label={t("FormFields.Email")}
          variant="outlined"
          fullWidth
          margin="normal"
          required
          type="email"
          name="email"
          value={formData.email}
          onChange={handleChange}
        />
        <TextField
          label={t("FormFields.Subject")}
          variant="outlined"
          fullWidth
          margin="normal"
          name="subject"
          value={formData.subject}
          onChange={handleChange}
        />
        <TextField
          label={t("FormFields.Message")}
          variant="outlined"
          fullWidth
          margin="normal"
          multiline
          rows={4}
          required
          name="message"
          value={formData.message}
          onChange={handleChange}
        />
        <Button
          variant="contained"
          color="primary"
          className="submit-button"
          type="submit"
        >
          {t("SubmitButton")}
        </Button>
      </Box>
    </Container>
  );
};

export default Contact;
