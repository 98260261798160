// src/pages/Jobs.tsx
import React from "react";
import { Container, Typography, Box } from "@mui/material";
import "./Jobs.scss";
import { useTranslation } from "react-i18next";

const Jobs: React.FC = () => {
    const { t } = useTranslation("jobs");

    return (
        <Container className="jobs-container">
            <Typography variant="h4" className="title">
                {t("Title")}
            </Typography>
            <Typography variant="body1" className="intro">
                {t("Intro")}
            </Typography>

            <Box className="no-jobs-box">
                <Typography variant="h6" className="no-jobs-title">
                    {t("NoJobsTitle")}
                </Typography>
                <Typography variant="body2" className="no-jobs-content">
                    {t("NoJobsContent")}
                </Typography>
            </Box>
        </Container>
    );
};

export default Jobs;
