// src/i18n/i18n.ts

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

//EN
import commonEN from '../locales/en/common.json';
import navbarEN from '../locales/en/navbar.json';
import footerEN from '../locales/en/footer.json';
import homeEN from '../locales/en/home.json';
import softwareSolutionsEN from '../locales/en/softwareSolutions.json';
import productsEN from '../locales/en/products.json';
import ourJourneyEN from '../locales/en/ourJourney.json';
import insightsEN from '../locales/en/insights.json';
import privacyPolicyEN from '../locales/en/privacyPolicy.json';
import jobsEN from '../locales/en/jobs.json';
import aboutUsEN from '../locales/en/aboutUs.json';
import contactEN from '../locales/en/contact.json';
import termsAndConditionsEN from '../locales/en/termsAndConditions.json';
import cookiePolicyEN from '../locales/en/cookiePolicy.json';

//VI
import commonVI from '../locales/vi/common.json';
import navbarVI from '../locales/vi/navbar.json';
import footerVI from '../locales/vi/footer.json';
import homeVI from '../locales/vi/home.json';
import softwareSolutionsVI from '../locales/vi/softwareSolutions.json';
import productsVI from '../locales/vi/products.json';
import ourJourneyVI from '../locales/vi/ourJourney.json';
import insightsVI from '../locales/vi/insights.json';
import privacyPolicyVI from '../locales/vi/privacyPolicy.json';
import jobsVI from '../locales/vi/jobs.json';
import aboutUsVI from '../locales/vi/aboutUs.json';
import contactVI from '../locales/vi/contact.json';
import termsAndConditionsVI from '../locales/vi/termsAndConditions.json';
import cookiePolicyVI from '../locales/vi/cookiePolicy.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                common: commonEN,
                navbar: navbarEN,
                footer: footerEN,
                home: homeEN,
                softwareSolutions: softwareSolutionsEN,
                products: productsEN,
                ourJourney: ourJourneyEN,
                insights: insightsEN,
                privacyPolicy: privacyPolicyEN,
                jobs: jobsEN,
                aboutUs: aboutUsEN,
                contact: contactEN,
                termsAndConditions: termsAndConditionsEN,
                cookiePolicy: cookiePolicyEN
            },
            vi: {
                common: commonVI,
                navbar: navbarVI,
                footer: footerVI,
                home: homeVI,
                softwareSolutions: softwareSolutionsVI,
                products: productsVI,
                ourJourney: ourJourneyVI,
                insights: insightsVI,
                privacyPolicy: privacyPolicyVI,
                jobs: jobsVI,
                aboutUs: aboutUsVI,
                contact: contactVI,
                termsAndConditions: termsAndConditionsVI,
                cookiePolicy: cookiePolicyVI
            },
        },
        fallbackLng: 'vi',
        debug: false,
        ns: [
            'common',
            'navbar',
            'footer',
            'home',
            'softwareSolutions',
            'products',
            'ourJourney',
            'insights',
            'privacyPolicy',
            'jobs',
            'aboutUs',
            'termsAndConditions',
            'cookiePolicy'
        ],
        defaultNS: 'common',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
