// src/pages/AboutUs.tsx
import React from "react";
import { Container, Typography } from "@mui/material";
import "./AboutUs.scss";
import { useTranslation } from "react-i18next";

const AboutUs: React.FC = () => {
    const { t } = useTranslation("aboutUs");

    return (
        <Container className="about-us-container">
            <Typography variant="h3" className="about-us-title">
                {t("Title")}
            </Typography>
            <Typography variant="body1" className="about-us-content">
                {t("Content1")}
            </Typography>
            <Typography variant="body1" className="about-us-content">
                {t("Content2")}
            </Typography>
            <Typography variant="body1" className="about-us-content">
                {t("Content3")}
            </Typography>
        </Container>
    );
};

export default AboutUs;
