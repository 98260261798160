// src/pages/TermsAndConditions.tsx
import React from "react";
import { Container, Typography } from "@mui/material";
import "./TermsAndConditions.scss";
import { useTranslation } from "react-i18next";

const TermsAndConditions: React.FC = () => {
    const { t } = useTranslation("termsAndConditions");

    return (
        <Container className="terms-container">
            <Typography variant="h4" className="title">
                {t("Title")}
            </Typography>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.Introduction.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.Introduction.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.IntellectualProperty.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.IntellectualProperty.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.UserObligations.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.UserObligations.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.LimitationOfLiability.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.LimitationOfLiability.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.ThirdPartyLinks.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.ThirdPartyLinks.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.PrivacyPolicy.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.PrivacyPolicy.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.Modifications.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.Modifications.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.GoverningLaw.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.GoverningLaw.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.ContactInformation.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.ContactInformation.Content")}
                </Typography>
            </section>
        </Container>
    );
};

export default TermsAndConditions;
