// src/pages/Products.tsx

import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import softwareImage from '../../assets/tavis-app.png';
import './Products.scss';
import { useTranslation } from 'react-i18next';

const Products: React.FC = () => {
    const { t } = useTranslation('products');

    // Lấy thông tin tính năng từ JSON với kiểu dữ liệu rõ ràng
    const featuresList = t('FeaturesList', { returnObjects: true }) as Array<{ title: string, description: string }>;

    // Lấy thông tin roadmap từ JSON với kiểu dữ liệu rõ ràng
    const roadmapItems = t('RoadmapItems', { returnObjects: true }) as Array<{ title: string, content: string }>;

    return (
        <Container className="products-container">
            <Typography variant="h4" className="title">
                {t('IntroducingTAVIS')}
            </Typography>
            <Typography variant="h4" className="title">
                {t('YourAIPersonalAssistant')}
            </Typography>
            <br />
            <br />
            {/* Story Section */}
            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t('StoryTitle')}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t('StoryContent')}
                </Typography>
            </section>

            <Box className="image-container">
                <img
                    src={softwareImage}
                    alt={t('ImageAlt')}
                    className="image"
                    style={{ width: '60%' }}
                />
            </Box>
            <br />
            <br />
            <br />

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t('CurrentFeaturesTitle')}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t('CurrentFeaturesIntro')}
                </Typography>
                <ul className="feature-list">
                    {featuresList.map((feature, index) => (
                        <li key={index}>
                            <strong>{feature.title}</strong> {feature.description}
                        </li>
                    ))}
                </ul>
            </section>

            {/* Future Development Roadmap */}
            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t('FutureDevelopmentTitle')}
                </Typography>

                <Box className="roadmap">
                    {roadmapItems.map((item, index) => (
                        <Box className="roadmap-item" key={index}>
                            <Typography variant="h6" className="roadmap-title">
                                {item.title}
                            </Typography>
                            <Typography variant="body2" className="roadmap-content">
                                {item.content}
                            </Typography>
                        </Box>
                    ))}
                </Box>
            </section>

            {/* Conclusion */}
            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t('WhyChooseTAVIS')}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t('ConclusionContent')}
                </Typography>
            </section>
        </Container>
    );
};

export default Products;
