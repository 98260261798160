// src/pages/CookiePolicy.tsx
import React from "react";
import { Container, Typography } from "@mui/material";
import "./CookiePolicy.scss";
import { useTranslation } from "react-i18next";

const CookiePolicy: React.FC = () => {
    const { t } = useTranslation("cookiePolicy");

    return (
        <Container className="cookie-policy-container">
            <Typography variant="h4" className="title">
                {t("Title")}
            </Typography>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.Introduction.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.Introduction.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.WhatAreCookies.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.WhatAreCookies.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.TypesOfCookies.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.TypesOfCookies.Content")}
                </Typography>
                <ul>
                    <li>{t("Sections.TypesOfCookies.List.0")}</li>
                    <li>{t("Sections.TypesOfCookies.List.1")}</li>
                    <li>{t("Sections.TypesOfCookies.List.2")}</li>
                    <li>{t("Sections.TypesOfCookies.List.3")}</li>
                </ul>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.HowWeUseCookies.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.HowWeUseCookies.Content")}
                </Typography>
                <ul>
                    <li>{t("Sections.HowWeUseCookies.List.0")}</li>
                    <li>{t("Sections.HowWeUseCookies.List.1")}</li>
                    <li>{t("Sections.HowWeUseCookies.List.2")}</li>
                    <li>{t("Sections.HowWeUseCookies.List.3")}</li>
                </ul>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.ManagingYourCookies.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.ManagingYourCookies.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.ThirdPartyCookies.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.ThirdPartyCookies.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.ChangesToPolicy.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.ChangesToPolicy.Content")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("Sections.ContactUs.Title")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("Sections.ContactUs.Content")}
                </Typography>
                <Typography variant="body1" className="contact-info">
                    {t("Sections.ContactUs.ContactEmail")} <br />
                    {t("Sections.ContactUs.ContactAddress")}
                </Typography>
            </section>
        </Container>
    );
};

export default CookiePolicy;
