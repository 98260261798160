import React from 'react';

const USFlag: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 7410 3900">
        <rect width="7410" height="3900" fill="#b22234" />
        <g fill="#fff">
            <rect y="300" width="7410" height="600" />
            <rect y="1500" width="7410" height="600" />
            <rect y="2700" width="7410" height="600" />
        </g>
        <rect width="2964" height="2100" fill="#3c3b6e" />
        <g fill="#fff">
            {Array.from({ length: 9 }).map((_, row) =>
                Array.from({ length: 11 }).map((_, col) => {
                    const x = col * 270 + (row % 2 === 0 ? 135 : 0);
                    const y = row * 210 + 105;
                    if (col < (row % 2 === 0 ? 6 : 5)) {
                        return (
                            <circle key={`${row}-${col}`} cx={x} cy={y} r="90" />
                        );
                    }
                    return null;
                })
            )}
        </g>
    </svg>
);

export default USFlag;
