// src/pages/PrivacyPolicy.tsx

import React from "react";
import { Container, Typography } from "@mui/material";
import "./PrivacyPolicy.scss";
import { useTranslation } from "react-i18next";

const PrivacyPolicy: React.FC = () => {
    const { t } = useTranslation("privacyPolicy");

    // Lấy các danh sách từ JSON và khai báo kiểu
    const informationCollectList: string[] = t("InformationCollectList", { returnObjects: true }) as string[];
    const howWeUseList: string[] = t("HowWeUseList", { returnObjects: true }) as string[];

    return (
        <Container className="privacy-policy-container">
            <Typography variant="h4" className="title">
                {t("Title")}
            </Typography>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("IntroductionTitle")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("IntroductionContent")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("InformationCollectTitle")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("InformationCollectContent")}
                </Typography>
                <ul>
                    {/* Không sử dụng .map() mà thay vào đó dùng vòng lặp thông thường */}
                    {informationCollectList.length > 0 && informationCollectList.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("HowWeUseTitle")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("HowWeUseContent")}
                </Typography>
                <ul>
                    {/* Tương tự với danh sách HowWeUse */}
                    {howWeUseList.length > 0 && howWeUseList.map((item, index) => (
                        <li key={index}>{item}</li>
                    ))}
                </ul>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("SharingInformationTitle")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("SharingInformationContent")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("DataSecurityTitle")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("DataSecurityContent")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("YourRightsTitle")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("YourRightsContent")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("ChangesTitle")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("ChangesContent")}
                </Typography>
            </section>

            <section className="section">
                <Typography variant="h6" className="section-title">
                    {t("ContactTitle")}
                </Typography>
                <Typography variant="body1" className="section-content">
                    {t("ContactContent")}
                </Typography>
                <Typography variant="body1" className="contact-info">
                    {t("ContactEmail")} <br />
                    {t("ContactAddress")}
                </Typography>
            </section>
        </Container>
    );
};

export default PrivacyPolicy;
