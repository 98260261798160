import React from 'react';

const VNFlag: React.FC = () => (
    <svg width="24" height="24" viewBox="0 0 512 512">
        <rect width="512" height="512" fill="#da251d" />
        <polygon
            fill="#ff0"
            points="256,128 294,224 394,224 314,288 341,384 256,328 171,384 198,288 118,224 218,224"
        />
    </svg>
);

export default VNFlag;
