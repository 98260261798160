// src/pages/OurJourney.tsx

import React from 'react';
import { Container, Typography } from '@mui/material';
import './OurJourney.scss';
import { useTranslation } from 'react-i18next';

const OurJourney: React.FC = () => {
    const { t } = useTranslation('ourJourney');

    return (
        <Container className="journey-container">
            {/* <Typography variant="h3" className="title">
                {t('Title')}
            </Typography> */}
            <Typography variant="h3" className="title" dangerouslySetInnerHTML={{ __html: t('Title') }} />

            <section className="journey-section">
                <Typography variant="body1" className="content" dangerouslySetInnerHTML={{ __html: t('JourneyIntro') }} />
            </section>

            <section className="journey-section">
                <Typography variant="h5" className="subtitle">
                    {t('BuildingFoundation')}
                </Typography>
                <Typography variant="body1" className="content" dangerouslySetInnerHTML={{ __html: t('FoundationContent') }} />
            </section>

            <section className="journey-section">
                <Typography variant="h5" className="subtitle">
                    {t('ExploringIndustry')}
                </Typography>
                <Typography variant="body1" className="content" dangerouslySetInnerHTML={{ __html: t('IndustryContent') }} />
            </section>

            <section className="journey-section">
                <Typography variant="h5" className="subtitle">
                    {t('ProvidingSolutions')}
                </Typography>
                <Typography variant="body1" className="content" dangerouslySetInnerHTML={{ __html: t('SolutionsContent') }} />
            </section>

            {/* <section className="journey-section">
                <Typography variant="h5" className="subtitle">
                    {t('IntroducingTAVIS')}
                </Typography>
                <Typography variant="body1" className="content" dangerouslySetInnerHTML={{ __html: t('TavisContent') }} />
            </section> */}

            <section className="journey-section">
                <Typography variant="body1" className="content" dangerouslySetInnerHTML={{ __html: t('FinalWords') }} />
            </section>
        </Container>
    );
};

export default OurJourney;
