// src/pages/Insights.tsx

import React from "react";
import { Container, Typography } from "@mui/material";
import "./Insights.scss";
import { useTranslation } from "react-i18next";

const Insights: React.FC = () => {
    const { t } = useTranslation('insights'); // Tên namespace là 'insights'

    return (
        <Container className="insights-container">
            <Typography variant="h4" className="insights-title">
                {t('Title')}
            </Typography>

            <section className="insights-section">
                <Typography variant="h5" className="insights-subtitle">
                    {t('SoftwareDevelopmentTitle')}
                </Typography>
                <Typography variant="body1" className="insights-content">
                    {t('SoftwareDevelopmentContent')}
                </Typography>
                <Typography variant="body1" className="insights-content">
                    {t('KeyInsights')}
                </Typography>
            </section>

            <section className="insights-section">
                <Typography variant="h5" className="insights-subtitle">
                    {t('AITitle')}
                </Typography>
                <Typography variant="body1" className="insights-content">
                    {t('AIContent')}
                </Typography>
                <Typography variant="body1" className="insights-content">
                    {t('AIFutureFocus')}
                </Typography>
            </section>

            <Typography variant="body1" className="insights-conclusion">
                {t('Conclusion')}
            </Typography>
        </Container>
    );
};

export default Insights;
