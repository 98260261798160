// src/pages/Home.tsx

import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Container, Typography, Button } from '@mui/material';
import softwareImage from '../../assets/banner.png';
import logoHelmet from '../../assets/logo-9BR.png';
import './Home.scss';
import InfoCircles from '../InfoCircles';
import { useTranslation } from 'react-i18next';

const Home: React.FC = () => {
    const { t } = useTranslation('home');

    return (
        <Container className="home-container">
            <Helmet>
                <title>{t('HelmetTitle')}</title>

                <meta name="description" content={t('HelmetDescription')} />
                <meta property="og:title" content={t('OGTitle')} />
                <meta property="og:description" content={t('OGDescription')} />
                <meta property="og:image" content={logoHelmet} />
                <meta property="og:url" content="https://9bricks.com" />
                <meta property="og:type" content="website" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={t('TwitterTitle')} />
                <meta name="twitter:description" content={t('TwitterDescription')} />
                <meta name="twitter:image" content={logoHelmet} />
            </Helmet>
            <div className="banner-container">
                <img src={softwareImage} alt={t('BannerAlt')} className="banner-image" />
                <div className="banner-text">
                    <Typography variant="h4" className="home-title">
                        {/*{t('BannerTitle')} */}
                        <span className="highlight">#9Bricks</span>
                    </Typography>
                    <Typography variant="h6" className="home-subtitle">
                        {t('BannerTitle')}
                    </Typography>
                </div>
            </div>

            <Typography variant="body1" className="home-content">
                {t('ContentPart2')}
            </Typography>

            <InfoCircles />

            <div>
                {/*<Typography className="home-additional-text" variant="body1">{t('AdditionalText1')}</Typography>*/}
                <Typography className="home-additional-text" variant="body1">{t('AdditionalText2')}</Typography>
            </div>

            <Button
                variant="contained"
                className="home-contact-button"
                component={Link}
                to="/contact"
            >
                {t('ContactButton')}
            </Button>
        </Container>
    );
};

export default Home;
