// src/App.tsx
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Home from "./components/pages/Home";
import AboutUs from "./components/pages/AboutUs";
import SoftwareSolutions from "./components/pages/SoftwareSolutions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import CookiePolicy from "./components/pages/CookiePolicy";
import Insights from "./components/pages/Insights";
import OurJourney from "./components/pages/OurJourney";
import Jobs from "./components/pages/Jobs";
import Products from "./components/pages/Products";
import Contact from "./components/pages/Contact";
import TermsAndConditions from "./components/pages/TermsAndConditions";

const App: React.FC = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/software-solutions" element={<SoftwareSolutions />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/cookie-policy" element={<CookiePolicy />} />
        <Route path="/insights" element={<Insights />} />
        <Route path="/our-journey" element={<OurJourney />} />
        <Route path="/jobs" element={<Jobs />} />
        <Route path="/products" element={<Products />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
